import { debug } from '/js/zonpotentie_local.js';
import { map } from '/js/zonpotentie_local.js';
import { mapObject } from '/js/zonpotentie_local.js';
import { addObject} from '/js/zonpotentie_local.js';

var lastColored;

class DragDrop {
  constructor(viewer) {
    this._viewer = viewer
    this.entity = null
    this.handler = null
    this.moving = false

    this._leftDown = this._leftDownHandler.bind(this)
    this._leftUp = this._leftUpHandler.bind(this)
    this._move = this._moveHandler.bind(this)

    this.handler = new Cesium.ScreenSpaceEventHandler(this._viewer.canvas)
  }

  enable() {
    //debug("enable");
    this.handler.setInputAction(this._leftDown, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    this.handler.setInputAction(this._leftUp, Cesium.ScreenSpaceEventType.LEFT_UP)
    this.handler.setInputAction(this._move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  disable() {
    //debug("disable");
    this._viewer.scene.screenSpaceCameraController.enableRotate = true

    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN)
    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP)
    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    this.moving = false
    this.entity = null
  }

  _leftDownHandler(e) {
    debug("leftDownHandler");
    this.entity = this._viewer.scene.pick(e.position)
    this.moving = true
    if (this.entity) {
      this._viewer.scene.screenSpaceCameraController.enableRotate = false
    }
  }

  _leftUpHandler() {
    debug("leftUpHandler");
    this.moving = false
    this.entity = null
    this._viewer.scene.screenSpaceCameraController.enableRotate = true
  }

  _moveHandler(e) {
    if (this.moving && this.entity && this.entity.id) {
      debug('_moveHandler: ' + this.entity.id);

      const ray = this._viewer.camera.getPickRay(e.endPosition)
      const cartesian = this._viewer.scene.globe.pick(ray, this._viewer.scene)

      const ellipsoid = this._viewer.scene.globe.ellipsoid
      const c = ellipsoid.cartesianToCartographic(cartesian)

      const origin = this.entity.id.position.getValue()
      const cc = ellipsoid.cartesianToCartographic(origin)

      const longitudeString = Cesium.Math.toDegrees(
        cc.longitude
      ).toFixed(2);
      const latitudeString = Cesium.Math.toDegrees(
        cc.latitude
      ).toFixed(2);
      const heightString = cc.height.toFixed(2);

      //debug('_moveHandler.longitudeString: ' + longitudeString);
      //debug('_moveHandler.latitudeString: ' + latitudeString);
      debug('_moveHandler.heightString: ' + heightString);

      cc.height = 0;

      const pickColor = Cesium.Color.SILVER.withAlpha(0.7);
      const defaultColor = Cesium.Color.SILVER.withAlpha(1.0);
      const pickedObjects = this._viewer.scene.drillPick(e.endPosition);
      const pickedEntities = new Cesium.EntityCollection();

      if (Cesium.defined(pickedObjects)) {

        pickedEntities.removeAll();
        
        debug("Aantal Objects onder muispointer:" + pickedObjects.length); 
        if (pickedObjects.length > 0) {
          for (let i = 0; i < pickedObjects.length; ++i) {
            //console.log(pickedObjects[i]);

            const entity = pickedObjects[i].id;
            pickedEntities.add(entity);
            //console.log(pickedEntities);

            if (pickedObjects[i].primitive && pickedObjects[i].primitive.isCesium3DTileset) {
              debug("is tileset object");
              debug("gid: " + pickedObjects[i].getProperty("gid"));
              pickedObjects[i].color = pickColor;
              debug ("set lastColored");
              lastColored = pickedObjects[i];
              //debug (lastColored);
            }
          }
        } else {
          if (lastColored) {
            debug("In lastColored");
            //debug (lastColored);
            lastColored.color = defaultColor;
          }
        }
      }  

      this.entity.id.position = new Cesium.CallbackProperty(function () {
        return new Cesium.Cartesian3.fromRadians(c.longitude, c.latitude, heightString)
      }, false)

    }
  }
}

function drag(ev, objectAttributes) {
    
    debug("drag");
    ev.dataTransfer.setData("Text", ev.target.id);
    ev.dataTransfer.setData("Attributes", objectAttributes);    
}
window.drag = drag;

function dragEnter(e) {
  e.preventDefault();
  return true;
}
window.dragEnter = dragEnter;

function dragDrop(e) {

  debug('dragDrop (+)');
  
  e.preventDefault();

  var offsets = document.getElementById("map");
  var top = offsets.offsetTop - window.scrollY;
  var left = offsets.offsetLeft - window.scrollX;;

  debug("offsets.offsetTop: " + top);
  debug("offsets.offsetLeft: " + left);
  
  var id = e.dataTransfer.getData("Text");
  var attributes = e.dataTransfer.getData("Attributes");

  // in case the object has attributes then it must be a custom 3d object
  if (attributes) {
    debug("Object: " + id);
    debug("Attributes: " + attributes);

    // calculate cartesian drop position 
    var cartesian = map.viewer.camera.pickEllipsoid(
      new Cesium.Cartesian3((e.clientX - left + 15), (e.clientY - top + 15)),
      map.viewer.scene.globe.ellipsoid
    );

    // convert cartesion to cartographic 
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    const longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
    const latitudeString = Cesium.Math.toDegrees(cartographic.latitude);

    debug("longitudeString: " + longitudeString);
    debug("latitudeString: " + latitudeString);

    // generate a new object
    var newMapObject = new mapObject("userObject", attributes, longitudeString, latitudeString);
    addObject(newMapObject);
  }

  debug('dragDrop (-)');
}
window.dragDrop = dragDrop;

function dragOver(e) {
  e.preventDefault();
}
window.dragOver = dragOver;

export { DragDrop, drag, dragEnter, dragDrop , dragOver } ;