const hespul = {
    "0": {
       "90": 87,
       "95": 87,
       "100": 87,
       "105": 87,
       "110": 87,
       "115": 87,
       "120": 87,
       "125": 87,
       "130": 87,
       "135": 87,
       "140": 87,
       "145": 87,
       "150": 87,
       "155": 87,
       "160": 87,
       "165": 87,
       "170": 87,
       "175": 87,
       "180": 87,
       "185": 87,
       "190": 87,
       "195": 87,
       "200": 87,
       "205": 87,
       "210": 87,
       "215": 87,
       "220": 87,
       "225": 87,
       "230": 87,
       "235": 87,
       "240": 87,
       "245": 87,
       "250": 87,
       "255": 87,
       "260": 87,
       "265": 87,
       "270": 87
    },
    "5": {
       "90": 88,
       "95": 88,
       "100": 89,
       "105": 89,
       "110": 89,
       "115": 90,
       "120": 90,
       "125": 90,
       "130": 91,
       "135": 91,
       "140": 91,
       "145": 91,
       "150": 91,
       "155": 91,
       "160": 91,
       "165": 91,
       "170": 91,
       "175": 92,
       "180": 92,
       "185": 92,
       "190": 91,
       "195": 91,
       "200": 91,
       "205": 91,
       "210": 91,
       "215": 91,
       "220": 91,
       "225": 91,
       "230": 90,
       "235": 90,
       "240": 90,
       "245": 91,
       "250": 91,
       "255": 91,
       "260": 90,
       "265": 89,
       "270": 89
    },
    "10": {
       "90": 89,
       "95": 90,
       "100": 91,
       "105": 91,
       "110": 91,
       "115": 92,
       "120": 92,
       "125": 93,
       "130": 94,
       "135": 94,
       "140": 94,
       "145": 95,
       "150": 95,
       "155": 95,
       "160": 95,
       "165": 95,
       "170": 95,
       "175": 96,
       "180": 96,
       "185": 96,
       "190": 95,
       "195": 95,
       "200": 95,
       "205": 95,
       "210": 95,
       "215": 95,
       "220": 94,
       "225": 94,
       "230": 94,
       "235": 93,
       "240": 93,
       "245": 93,
       "250": 92,
       "255": 91,
       "260": 91,
       "265": 90,
       "270": 90
    },
    "15": {
       "90": 88,
       "95": 89,
       "100": 90,
       "105": 91,
       "110": 92,
       "115": 93,
       "120": 93,
       "125": 94,
       "130": 95,
       "135": 95,
       "140": 95,
       "145": 96,
       "150": 96,
       "155": 96,
       "160": 97,
       "165": 97,
       "170": 97,
       "175": 97,
       "180": 97,
       "185": 97,
       "190": 97,
       "195": 97,
       "200": 97,
       "205": 96,
       "210": 96,
       "215": 96,
       "220": 95,
       "225": 95,
       "230": 95,
       "235": 94,
       "240": 94,
       "245": 93,
       "250": 92,
       "255": 91,
       "260": 91,
       "265": 90,
       "270": 89
    },
    "20": {
       "90": 87,
       "95": 88,
       "100": 89,
       "105": 90,
       "110": 91,
       "115": 92,
       "120": 93,
       "125": 94,
       "130": 95,
       "135": 96,
       "140": 96,
       "145": 97,
       "150": 97,
       "155": 97,
       "160": 98,
       "165": 98,
       "170": 98,
       "175": 98,
       "180": 98,
       "185": 98,
       "190": 98,
       "195": 98,
       "200": 98,
       "205": 97,
       "210": 97,
       "215": 97,
       "220": 96,
       "225": 96,
       "230": 96,
       "235": 95,
       "240": 94,
       "245": 93,
       "250": 92,
       "255": 91,
       "260": 90,
       "265": 89,
       "270": 88
    },
    "25": {
       "90": 87,
       "95": 88,
       "100": 89,
       "105": 90,
       "110": 91,
       "115": 92,
       "120": 93,
       "125": 94,
       "130": 95,
       "135": 96,
       "140": 97,
       "145": 98,
       "150": 98,
       "155": 99,
       "160": 99,
       "165": 99,
       "170": 99,
       "175": 99,
       "180": 99,
       "185": 99,
       "190": 99,
       "195": 99,
       "200": 99,
       "205": 98,
       "210": 97,
       "215": 97,
       "220": 97,
       "225": 96,
       "230": 96,
       "235": 95,
       "240": 94,
       "245": 93,
       "250": 92,
       "255": 91,
       "260": 89,
       "265": 88,
       "270": 87
    },
    "30": {
       "90": 86,
       "95": 87,
       "100": 88,
       "105": 89,
       "110": 90,
       "115": 92,
       "120": 93,
       "125": 94,
       "130": 95,
       "135": 96,
       "140": 97,
       "145": 98,
       "150": 98,
       "155": 98,
       "160": 99,
       "165": 99,
       "170": 99,
       "175": 100,
       "180": 100,
       "185": 100,
       "190": 100,
       "195": 100,
       "200": 100,
       "205": 99,
       "210": 98,
       "215": 98,
       "220": 97,
       "225": 96,
       "230": 96,
       "235": 95,
       "240": 94,
       "245": 93,
       "250": 91,
       "255": 90,
       "260": 89,
       "265": 87,
       "270": 86
    },
    "35": {
       "90": 84,
       "95": 85,
       "100": 87,
       "105": 88,
       "110": 89,
       "115": 91,
       "120": 92,
       "125": 93,
       "130": 95,
       "135": 96,
       "140": 97,
       "145": 98,
       "150": 98,
       "155": 98,
       "160": 99,
       "165": 99,
       "170": 99,
       "175": 100,
       "180": 100,
       "185": 100,
       "190": 100,
       "195": 100,
       "200": 100,
       "205": 99,
       "210": 98,
       "215": 98,
       "220": 97,
       "225": 96,
       "230": 95,
       "235": 94,
       "240": 93,
       "245": 92,
       "250": 90,
       "255": 89,
       "260": 88,
       "265": 86,
       "270": 85
    },
    "40": {
       "90": 82,
       "95": 83,
       "100": 85,
       "105": 86,
       "110": 87,
       "115": 89,
       "120": 90,
       "125": 92,
       "130": 94,
       "135": 95,
       "140": 96,
       "145": 97,
       "150": 97,
       "155": 98,
       "160": 99,
       "165": 99,
       "170": 99,
       "175": 100,
       "180": 100,
       "185": 100,
       "190": 99,
       "195": 99,
       "200": 99,
       "205": 98,
       "210": 98,
       "215": 98,
       "220": 97,
       "225": 96,
       "230": 95,
       "235": 93,
       "240": 92,
       "245": 91,
       "250": 89,
       "255": 88,
       "260": 87,
       "265": 85,
       "270": 84
    },
    "45": {
       "90": 80,
       "95": 82,
       "100": 84,
       "105": 85,
       "110": 86,
       "115": 88,
       "120": 89,
       "125": 91,
       "130": 93,
       "135": 94,
       "140": 95,
       "145": 96,
       "150": 96,
       "155": 97,
       "160": 98,
       "165": 98,
       "170": 98,
       "175": 99,
       "180": 99,
       "185": 99,
       "190": 98,
       "195": 98,
       "200": 98,
       "205": 97,
       "210": 97,
       "215": 96,
       "220": 95,
       "225": 95,
       "230": 93,
       "235": 92,
       "240": 91,
       "245": 89,
       "250": 88,
       "255": 87,
       "260": 85,
       "265": 84,
       "270": 82
    },
    "50": {
       "90": 78,
       "95": 80,
       "100": 82,
       "105": 84,
       "110": 85,
       "115": 87,
       "120": 88,
       "125": 89,
       "130": 91,
       "135": 92,
       "140": 93,
       "145": 94,
       "150": 95,
       "155": 95,
       "160": 96,
       "165": 96,
       "170": 96,
       "175": 97,
       "180": 97,
       "185": 97,
       "190": 97,
       "195": 97,
       "200": 97,
       "205": 96,
       "210": 96,
       "215": 95,
       "220": 94,
       "225": 93,
       "230": 92,
       "235": 90,
       "240": 89,
       "245": 88,
       "250": 86,
       "255": 85,
       "260": 84,
       "265": 82,
       "270": 80
    },
    "55": {
       "90": 76,
       "95": 78,
       "100": 80,
       "105": 82,
       "110": 83,
       "115": 85,
       "120": 86,
       "125": 87,
       "130": 89,
       "135": 90,
       "140": 91,
       "145": 92,
       "150": 93,
       "155": 94,
       "160": 94,
       "165": 94,
       "170": 95,
       "175": 95,
       "180": 95,
       "185": 95,
       "190": 95,
       "195": 95,
       "200": 94,
       "205": 94,
       "210": 93,
       "215": 92,
       "220": 91,
       "225": 90,
       "230": 89,
       "235": 88,
       "240": 86,
       "245": 85,
       "250": 83,
       "255": 82,
       "260": 80,
       "265": 78,
       "270": 78
    },
    "60": {
       "90": 74,
       "95": 76,
       "100": 78,
       "105": 79,
       "110": 81,
       "115": 83,
       "120": 84,
       "125": 85,
       "130": 86,
       "135": 87,
       "140": 88,
       "145": 89,
       "150": 90,
       "155": 90,
       "160": 91,
       "165": 91,
       "170": 92,
       "175": 93,
       "180": 93,
       "185": 93,
       "190": 93,
       "195": 93,
       "200": 93,
       "205": 92,
       "210": 92,
       "215": 91,
       "220": 90,
       "225": 89,
       "230": 88,
       "235": 87,
       "240": 86,
       "245": 85,
       "250": 83,
       "255": 81,
       "260": 80,
       "265": 78,
       "270": 76
    },
    "65": {
       "90": 72,
       "95": 74,
       "100": 76,
       "105": 77,
       "110": 78,
       "115": 80,
       "120": 81,
       "125": 82,
       "130": 84,
       "135": 85,
       "140": 86,
       "145": 87,
       "150": 88,
       "155": 88,
       "160": 89,
       "165": 89,
       "170": 89,
       "175": 90,
       "180": 90,
       "185": 90,
       "190": 90,
       "195": 90,
       "200": 90,
       "205": 89,
       "210": 89,
       "215": 88,
       "220": 87,
       "225": 87,
       "230": 85,
       "235": 84,
       "240": 83,
       "245": 82,
       "250": 80,
       "255": 79,
       "260": 77,
       "265": 75,
       "270": 73
    },
    "70": {
       "90": 69,
       "95": 71,
       "100": 73,
       "105": 74,
       "110": 75,
       "115": 77,
       "120": 78,
       "125": 79,
       "130": 81,
       "135": 82,
       "140": 83,
       "145": 84,
       "150": 85,
       "155": 85,
       "160": 86,
       "165": 86,
       "170": 86,
       "175": 87,
       "180": 87,
       "185": 87,
       "190": 87,
       "195": 87,
       "200": 87,
       "205": 86,
       "210": 86,
       "215": 86,
       "220": 85,
       "225": 84,
       "230": 83,
       "235": 81,
       "240": 80,
       "245": 79,
       "250": 77,
       "255": 76,
       "260": 74,
       "265": 72,
       "270": 70
    },
    "75": {
       "90": 66,
       "95": 68,
       "100": 70,
       "105": 71,
       "110": 72,
       "115": 74,
       "120": 75,
       "125": 76,
       "130": 78,
       "135": 79,
       "140": 80,
       "145": 81,
       "150": 81,
       "155": 82,
       "160": 83,
       "165": 83,
       "170": 83,
       "175": 84,
       "180": 84,
       "185": 84,
       "190": 84,
       "195": 84,
       "200": 84,
       "205": 83,
       "210": 83,
       "215": 82,
       "220": 81,
       "225": 81,
       "230": 79,
       "235": 78,
       "240": 77,
       "245": 76,
       "250": 74,
       "255": 73,
       "260": 71,
       "265": 69,
       "270": 68
    },
    "80": {
       "90": 63,
       "95": 65,
       "100": 67,
       "105": 68,
       "110": 69,
       "115": 71,
       "120": 72,
       "125": 73,
       "130": 74,
       "135": 75,
       "140": 76,
       "145": 77,
       "150": 77,
       "155": 78,
       "160": 79,
       "165": 79,
       "170": 79,
       "175": 80,
       "180": 80,
       "185": 80,
       "190": 80,
       "195": 80,
       "200": 80,
       "205": 79,
       "210": 79,
       "215": 79,
       "220": 78,
       "225": 77,
       "230": 76,
       "235": 75,
       "240": 74,
       "245": 73,
       "250": 71,
       "255": 69,
       "260": 68,
       "265": 66,
       "270": 65
    },
    "85": {
       "90": 60,
       "95": 61,
       "100": 63,
       "105": 64,
       "110": 65,
       "115": 67,
       "120": 68,
       "125": 69,
       "130": 70,
       "135": 71,
       "140": 72,
       "145": 73,
       "150": 73,
       "155": 74,
       "160": 75,
       "165": 75,
       "170": 75,
       "175": 76,
       "180": 76,
       "185": 76,
       "190": 76,
       "195": 76,
       "200": 76,
       "205": 75,
       "210": 75,
       "215": 75,
       "220": 74,
       "225": 73,
       "230": 72,
       "235": 71,
       "240": 70,
       "245": 68,
       "250": 67,
       "255": 66,
       "260": 64,
       "265": 63,
       "270": 62
    },
    "90": {
       "90": 56,
       "95": 57,
       "100": 59,
       "105": 60,
       "110": 61,
       "115": 63,
       "120": 64,
       "125": 65,
       "130": 66,
       "135": 67,
       "140": 68,
       "145": 69,
       "150": 69,
       "155": 70,
       "160": 71,
       "165": 71,
       "170": 71,
       "175": 71,
       "180": 71,
       "185": 71,
       "190": 71,
       "195": 71,
       "200": 71,
       "205": 71,
       "210": 71,
       "215": 71,
       "220": 70,
       "225": 69,
       "230": 68,
       "235": 66,
       "240": 65,
       "245": 64,
       "250": 63,
       "255": 62,
       "260": 61,
       "265": 59,
       "270": 58
    }
 }

 export { hespul };