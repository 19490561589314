import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Wireframe } from "three/examples/jsm/lines/Wireframe.js";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry.js";

import earcut from 'earcut';
import VertexShaderWithLights from './../shaders/withLights/vertex.glsl'
import FragmentShaderWithLights from './../shaders/withLights/fragment.glsl'
import VertexShaderWithoutLights from './../shaders/withoutLights/vertex.glsl'
import FragmentShaderWithoutLights from './../shaders/withoutLights/fragment.glsl'

const debuggingThreejs = false;
debug('debuggingThreejs: ' + debuggingThreejs);
const debuggingHelpers = false;
debug('debuggingHelpers: ' + debuggingHelpers);

// colors
const defaultColor =  new THREE.Vector3 (1.0, 1.0, 1.0);
const floorColor = new THREE.Vector3 (0.8, 0.8, 0.8);
const roofColor =  new THREE.Vector3 (1.0, 0.0, 0.0);

function test_message() {
  alert("Hello");
}
window.test_message = test_message;

function debug(message) {
  if (debuggingThreejs) {
    console.log (message);
  }
}

debug('width: ' + window.screen.width);
debug('height: ' + window.screen.height);

var parent = document.getElementById("objectViewer");
var width = (parent.offsetWidth);
var height = width;
debug('width: ' + width);
debug('height: ' + height);

//New scene and camera
var scene = new THREE.Scene();

var camera = new THREE.PerspectiveCamera( 60, width / height, 0.1, 4000 );

if (debuggingHelpers) {
  const axesHelper = new THREE.AxesHelper(2);
  scene.add(axesHelper);

  const helper = new THREE.CameraHelper(camera);
  scene.add(helper);
}

//New Renderer
var renderer = new THREE.WebGLRenderer();
renderer.setSize(width, height);
var canvas = renderer.domElement;
canvas.style.display = "block";
canvas.id = "threeObject";
var ov = document.getElementById('objectViewer');
ov.style.display = 'none';
ov.appendChild(canvas);

//Enable controls
debug('enable controls');
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.dampingFactor = 0.25;

// Add lightning
var light = new THREE.DirectionalLight(0x404040, 3);
light.position.set( 1, 2, 3 );; //(1, 1, 0);
//scene.add(light);

const ambientLight = new THREE.AmbientLight( 0x666666, 0.7 ); // soft white light
//scene.add( ambientLight );

const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
//scene.add(directionalLight);

//Render the image
debug('render');
function render() {
  requestAnimationFrame(render);
  scene.background = new THREE.Color('#FFFFFF');
  renderer.render(scene, camera);
  controls.update();
}

render();

listenEvent(window, "load", function () {
  startListening(scene);
});

function resizeCanvas() {

  var parent = document.getElementById("objectViewer");
  var width = (parent.offsetWidth - 10);
  var height = width;

  renderer = new THREE.WebGLRenderer({ canvas: canvas });
  renderer.setSize(width, height);

  debug('width: ' + width);
  debug('height: ' + height);

}
window.resizeCanvas = resizeCanvas;

function drawGeo(json, vertices, radius, shape, options) {

  debug('drawGeo (+)');
  debug('json:');
  debug(json);
  
  var json_geom = createGeometryArray(json);

  debug('json_geom:');
  debug (json_geom);

  const boundaries = json_geom[0].coordinates;
  debug('boundaries.lenth: ' + boundaries.length);
  //debug (boundaries);

  debug('modelgroup');
  let modelgroup = new THREE.Group();

  debug ('scene.children.length before: ' + scene.children.length)
  for (var c = 0; c < scene.children.length; c++) {
    debug ('scene.children[c].type: ' + scene.children[c].type); 

    if (scene.children[c].type == 'Group') {
      scene.remove(scene.children[c]);
    }
  }  
  debug ('scene.children.length after: ' + scene.children.length)

  console.time('building');

  debug('computeMatrix and bounding box (+)');
  //console.time('computeMatrix');
  let coordinate_arrayMerged = [];
  const normGeom = new THREE.BufferGeometry();
  
  for (var geom_num = 0; geom_num < json_geom.length; geom_num++) {
    for (var polygon_num = 0; polygon_num < json_geom[geom_num].coordinates.length; polygon_num++) {
    //  for (var polygon_num = 0; polygon_num < 1; polygon_num++) {  

      //debug('polygon_num: ' + polygon_num)
      for (var segment_num = 0; segment_num < json_geom[geom_num].coordinates[
        polygon_num].length; segment_num++) {

        var coordinate_array = json_geom[geom_num].coordinates[polygon_num][segment_num];
        //debug(coordinate_array);

        for (let point_num = 0; point_num < coordinate_array.length; point_num++) {

          coordinate_arrayMerged.push(coordinate_array[point_num]);

        }
      }
    }
  }
  //debug('coordinate_arrayMerged:');
  //debug(coordinate_arrayMerged);
  let verticesArray = new Float32Array( coordinate_arrayMerged.map( v => [ v[ 0 ], v[ 1 ], v[ 2 ] ] ).flat() );
  //debug('verticesArray:');
  //debug(verticesArray);

  normGeom.setAttribute('position', new THREE.BufferAttribute(verticesArray, 3));
  normGeom.computeBoundingBox();

  let boundingBox = normGeom.boundingBox;
  debug('boundingBox');
  debug(boundingBox);

  const centre = new THREE.Vector3();
  normGeom.boundingBox.getCenter(centre); 
  centre.setZ(0);

  const s = 1;

  const matrix = new THREE.Matrix4();

  matrix.set(
    s, 0, 0, - s * centre.x,
    0, s, 0, - s * centre.y,
    0, 0, s, - s * centre.z,
    0, 0, 0, 1
  );

  debug('matrix');
  debug(matrix);
  
  let bbox = boundingBox.applyMatrix4(matrix);
  debug('bbox: ' + JSON.stringify(bbox));

  const box_centre = new THREE.Vector3();
  bbox.getCenter( box_centre );
  debug ('box_centre: ');
  debug ( box_centre);

  //console.timeEnd('computeMatrix');
  debug('computeMatrix and bounding box (-)');

  if (debuggingHelpers) {
    // helper point for projecting the center
    debug('Add Points');
    const pointGeometry = new THREE.BoxGeometry(10, 10, 10);
    const materialPoint = new THREE.PointsMaterial({ color: 'blue' });
    materialPoint.size = 5;
    const points = new THREE.Points(pointGeometry, materialPoint);
    scene.add(points);
  }
  
  debug('boundaries/polygons: ' + json_geom[0].coordinates.length);

  //console.time ('polygon loop');
  for ( let i = 0; i < boundaries.length; i ++ ) {
  //for ( let i = 0; i < 1; i ++ ) {
    debug ('Boundary number: ' + i);
    debug (boundaries[i]);

    // define material for each polygon
    const materialWithLights = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(0xffffff) },
        lightDirection: { value: new THREE.Vector3(-1.0, -1.0, -1.0).normalize() },
        uColor: { value: new THREE.Vector3( 0.0, 0.0, 1.0) }
      },
      vertexShader: VertexShaderWithLights,
      fragmentShader: FragmentShaderWithLights,
      wireframe: false,
      side: THREE.DoubleSide,
      transparent: true,
    })

    const materialWithoutLights = new THREE.ShaderMaterial({
      uniforms: {
        uColor: { value: new THREE.Vector3( 0.0, 0.0, 1.0) }
      },
      vertexShader: VertexShaderWithoutLights,
      fragmentShader: FragmentShaderWithoutLights,
      wireframe: false,
      side: THREE.DoubleSide,
      transparent: true,
    })

    let boundary = [];
    let holes = [];
    let pList = [];
    let geometry = new THREE.BufferGeometry();
    var boundaryNew = [];
    var verticesArrayPolygon = new Float32Array([]);
    let indEarcut = new Uint32Array([]);
    let surfaceArray;
    let surfaceType;
    
    // get semantics array
    surfaceArray = json.features[0].semantics; 
    debug('surfaceArray');
    debug(surfaceArray);

    // pick the value from the sematics array based on the boundary number
    surfaceType = surfaceArray[i];
    debug ('surfaceType: ' + surfaceType);

    var boundaryArray = boundaries[i];
    for (var a = 0; a < boundaryArray.length; a++) {
       // remove last vertex as its duplicated with the array
      boundaryArray[a].pop();
      // de rest toevoegen aan een nieuwe array
      boundaryNew.push (boundaryArray[a]);
    }  
    debug ('boundaryNew: ');
    debug (boundaryNew);

    debug('boundaryNew.length: ' + boundaryArray.length);
    //console.time('holes');
    for (let j = 0; j < boundaryArray.length; j++) {

      if (boundary.length > 0) {
        holes.push(boundary.length);
      }
      boundary.push(...boundaries[i][j]);

    }
    debug('holes: ');
    debug(holes);
    //console.timeEnd('holes');

    //create list of points
    debug('boundary.length: ' + boundaryArray.length);
    //console.time('list_of_points');    
    let boundary_arrayMerged = [];
    for (let b = 0; b < boundaryArray.length; b++) {

      for (let k = 0; k < boundaryArray[b].length; k++) {

        pList.push({
          x: boundaryArray[b][k][0],
          y: boundaryArray[b][k][1],
          z: boundaryArray[b][k][2]
        });

      }
    }
    debug ('pList: ');
    debug(pList);

    debug ('boundary_arrayMerged');
    debug (boundary_arrayMerged);
    verticesArrayPolygon = new Float32Array( pList.map( v => [ v.x, v.y, v.z ] ).flat() );
    debug ('verticesArrayPolygoNew');
    debug (verticesArrayPolygon);
    //console.timeEnd('list_of_points');

    //get normal of these points
    const normal = getNewellsNormal(pList);
    debug('normal');
    debug(normal);

    //convert to 2d (for triangulation)
    let pv = [];
    for (let k = 0; k < pList.length; k++) {

      const re = to_2d(pList[k], normal);
      pv.push(re.x);
      pv.push(re.y);

    }

    //triangulate
    debug('pv');
    //debug(pv);
    const tr = earcut(pv, holes, 2);
    debug('tr');
    //debug(tr);

    //create faces based on triangulation
    //console.time('faces');
    for (let k = 0; k < tr.length; k += 3) {

      for (let n = 0; n < 3; n++) {

        const vertex = [tr[k + n]];
        //debug('vertex: ' + vertex);

        indEarcut.push = function () {
          indEarcut = new Uint32Array([...indEarcut, ...arguments]);
        };
        indEarcut.push(vertex);

      }

    }
    //console.timeEnd('faces');

    //debug('Draw polygon new via buffer');
    geometry.setAttribute('position', new THREE.BufferAttribute(verticesArrayPolygon, 3));
    geometry.setIndex(new THREE.BufferAttribute(indEarcut, 1));

    debug ('Calculate UV (for textures and shading');
    let pos = geometry.attributes.position;
    let bbox = new THREE.Box3().setFromBufferAttribute( pos );
    let size = new THREE.Vector3();
    bbox.getSize(size);

    let v3 = new THREE.Vector3();
    for(let i = 0; i < pos.count; i++){
      v3.fromBufferAttribute(pos, i);
      let u = (v3.x - bbox.min.x) / size.x;
      let v = (v3.y - bbox.min.y) / size.y;
      var uvs = [
        u,v, i
      ];

      for(let i = 0; i < pos.count; i++){
        v3.fromBufferAttribute(pos, i);
        let u = (v3.x - bbox.min.x) / size.x;
        let v = (v3.y - bbox.min.y) / size.y;

        uvs.push(...[
          u, v
        ]); 
      }
    }

    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array( uvs ), 2 ) );   
    geometry.attributes.uv.needsUpdate = true;

    geometry.computeVertexNormals();
    geometry.normalizeNormals();
    geometry.applyMatrix4(matrix);

    debug('Draw faces');
    let mesh;
   
    debug  ('surface type: ' + surfaceType);
    // 1 is roof surface 
    if (surfaceType === 1) {
      debug  ('roof surface');
      materialWithoutLights.uniforms.uColor.value = roofColor;
      mesh = new THREE.Mesh(geometry, materialWithoutLights);  
    } else if (surfaceType === 0) {
      debug  ('floor surface ');
      materialWithoutLights.uniforms.uColor.value = floorColor;
      mesh = new THREE.Mesh(geometry, materialWithoutLights);
    }
    else {
      debug  ('other surface ');
      materialWithLights.uniforms.uColor.value = defaultColor;
      mesh = new THREE.Mesh(geometry, materialWithLights);
    };


    if (debuggingHelpers) {
      let helper;
      if(THREE.VertexNormalsHelper){
          helper = new THREE.VertexNormalsHelper(mesh, 2, 0x00ff00, 1);
          modelgroup.add(helper);
      }
    }

    debug('Draw outerlines');
    var edgesGeometry = new THREE.EdgesGeometry( geometry );
  	var lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry( edgesGeometry );

	  var matLine = new LineMaterial( {
  		color: 0x4080ff,  
	  	linewidth: 0.002,
	  } );

	  var wireframe = new Wireframe( lineGeometry, matLine );
	  wireframe.computeLineDistances();
	  wireframe.scale.set( 1, 1, 1 );
	  //modelgroup.add( wireframe );

    debug('mesh');
    debug (mesh);
    //console.time('addModelgroup');
    modelgroup.add(mesh);
    //console.timeEnd('addModelgroup');

  } 
  //console.timeEnd ('polygon loop');
  
  // center the object
  modelgroup.rotation.x = (Math.PI * -0.5);  //-1.4;
  modelgroup.position.y = (box_centre.z * -1 ); // object height devided by 2

  debug ('modelgroup.position.x: ' + modelgroup.position.x);
  debug ('modelgroup.position.y: ' + modelgroup.position.y);
  debug ('modelgroup.position.z: ' + modelgroup.position.z);

  if (debuggingHelpers) {
   let objectBox = new THREE.Box3().setFromObject(modelgroup);
    let helper = new THREE.Box3Helper(objectBox, new THREE.Color(0xFF8551));
    scene.add (helper);
    debug('newBounds is >>>>>>>>', objectBox);
  }

  scene.add(modelgroup);
  debug('modelgroup');
  debug  (modelgroup);

  console.timeEnd('building');

  fitCameraToSelection(camera, controls, bbox, 1.4);

  debug('drawGeo (-)');

}
window.drawGeo = drawGeo;


function getNewellsNormal(indices) {

  // find normal with Newell's method
  let n = [0.0, 0.0, 0.0];

  for (let i = 0; i < indices.length; i++) {

    let nex = i + 1;

    if (nex == indices.length) {

      nex = 0;

    }

    n[0] = n[0] + ((indices[i].y - indices[nex].y) * (indices[i].z + indices[nex].z));
    n[1] = n[1] + ((indices[i].z - indices[nex].z) * (indices[i].x + indices[nex].x));
    n[2] = n[2] + ((indices[i].x - indices[nex].x) * (indices[i].y + indices[nex].y));

  }

  let b = new THREE.Vector3(n[0], n[1], n[2]);
  return (b.normalize());

}

function to_2d(p, n) {

  p = new THREE.Vector3(p.x, p.y, p.z);
  let x3 = new THREE.Vector3(1.1, 1.1, 1.1);
  if (x3.distanceTo(n) < 0.01) {

    x3.add(new Vector3(1.0, 2.0, 3.0));

  }

  let tmp = x3.dot(n);
  let tmp2 = n.clone();
  tmp2.multiplyScalar(tmp);
  x3.sub(tmp2);
  x3.normalize();
  let y3 = n.clone();
  y3.cross(x3);
  let x = p.dot(x3);
  let y = p.dot(y3);
  let re = { x: x, y: y };
  return re;

}

function clearDuplicateVertices(geometry) {
  //store the vertex index changes
  var data = [];
  var vertices = [];
  var faces = [];

  //vertices
  for (var v = 0; v < geometry.vertices.length; v++) {
      var vertex = geometry.vertices[v];

      //see if we have that vertex already
      var newIndex = -1;
      for (var i = 0; i < vertices.length; i++) {
          if (vertices[i].equals(vertex))
          {
              newIndex = i;
              break;
          }
      }

      if (newIndex > -1)
      {
          data.push({oldIndex: v, newIndex: newIndex});
      } else
      {
          vertices.push(vertex);
          data.push({oldIndex: v, newIndex: vertices.length - 1});
      }
  }

  //faces
  for (var f = 0; f < geometry.faces.length; f++) {
      var currentFace = geometry.faces[f];

      var a = currentFace.a;
      var b = currentFace.b;
      var c = currentFace.c;
      for (var i = 0; i < data.length; i++) {
          var currentData = data[i];

          if (a === currentData.oldIndex) {
              a = currentData.newIndex;
          }

          if (b === currentData.oldIndex) {
              b = currentData.newIndex;
          }

          if (c === currentData.oldIndex) {
              c = currentData.newIndex;
          }
      }
      faces.push(new THREE.Face3(a, b, c));
  }

  //copy
  var geo = new THREE.Geometry();
  geo.vertices = vertices;
  geo.faces = faces;
  geo.computeFaceNormals();
  return geo;
}

function fitCameraToSelection(camera, controls, box, fitOffset = 2.0) {

  // From https://discourse.threejs.org/t/camera-zoom-to-fit-object/936/24

  debug('fitCameraToSelection.box: ');
  debug (box);

  var x = box.max.x;
  var y = box.max.y;
  var z = box.max.z;

  debug('maxBoundingBoxX: ' + x);
  debug('maxBoundingBoxY: ' + y);
  debug('maxBoundingBoxZ: ' + z);

  const size = new THREE.Vector3();
  const center = new THREE.Vector3();

  const test_center = new THREE.Vector3(0, 0, 0);

  box.getSize(size);
  box.getCenter(center);

  debug('size: ' + JSON.stringify(size));
  debug('center: ' + JSON.stringify(center));
  
  const maxSize = Math.max(x, y, z);
  debug ('maxSize: ' + maxSize);
  const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
  debug ('fitHeightDistance: ' + fitHeightDistance);
  debug ('camera.aspect: ' + camera.aspect);
  const fitWidthDistance = fitHeightDistance / camera.aspect;
  debug ('fitWidthDistance: ' + fitWidthDistance);
  let distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
  debug('distance: ' + distance);

  const maxSizeObject = Math.max(size.x, size.y, size.z);
  debug ('maxSizeObject: ' + maxSizeObject);

  distance = maxSizeObject;

  //camera.near = 100;
  //camera.far = 100;

  camera.position.x = 0;
  camera.position.z = distance * fitOffset;
  camera.position.y = distance / 3; // angle

  debug('camera.position.x: ' + camera.position.x);
  debug('camera.position.y: ' + camera.position.y);
  debug('camera.position.z: ' + camera.position.z);

  controls.maxDistance = distance * 10;
  controls.target.copy(test_center);

  camera.near = distance / 100;
  camera.far = distance * 100;
  camera.updateProjectionMatrix();
  
  debug (camera);
  
  controls.update();

}

function listenEvent(eventObj, event, eventHandler) {
  if (eventObj.addEventListener) {
    eventObj.addEventListener(event, eventHandler, false);
  }
  else if (eventObj.attachEvent) {
    event = "on" + event;
    eventObj.attachEvent(event, eventHandler);
  }
  else {
    eventObj["on" + event] = eventHandler;
  }
}

function startListening(e) {

  debug('Activeer listeners (+)');
  debug('Activeer listeners (-)');
}

function log_positions(e) {

  debug('Activeer log_positions (+)');

  var scene = new THREE.Scene();
  document.getElementById('threeObject').scene=scene;
  debug(scene);

  debug('Activeer log_positions (-)');

}
window.log_positions = log_positions; 


function createGeometryArray(json) {

  debug('createGeometryArray (+)');
  debug('json.type: ' + json.type);

  var geometry_array = [];

  if (json.type == 'Feature') {
    geometry_array.push(json.geometry);
  } else if (json.type == 'FeatureCollection') {
    for (var feature_num = 0; feature_num < json.features.length; feature_num++) {
      geometry_array.push(json.features[feature_num].geometry);
    }
  } else if (json.type == 'GeometryCollection') {
    for (var geom_num = 0; geom_num < json.geometries.length; geom_num++) {
      geometry_array.push(json.geometries[geom_num]);
    }
  } else {
    throw new Error('The geoJSON is not valid.');
  }

  //alert(geometry_array.length);

  debug('createGeometryArray (-)');

  return geometry_array;
}

export { drawGeo, resizeCanvas, test_message, log_positions};